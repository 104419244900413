<template>
    <div>
        <b-row class="my-2">
            <b-col sm="6" md="5">
                <b>ASSETS-ON-HIRE</b>
                <p class="text-muted" style="font-style: italic;">Note that this is just an estimated computation for
                    rental billing</p>
            </b-col>
            <b-col sm="6" md="4" offset-md="3" class="text-md-right">
                <b-input-group prepend="Show" append="/ Page">
                    <b-form-select :options="pageOptions" v-model="perPage" />
                </b-input-group>
            </b-col>
        </b-row>

        <b-table show-empty striped hover :items="assetsOnHire" :fields="fields" :current-page="currentPage"
            :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection" responsive>
            <template v-slot:cell(accountNo)="row">
                <span class="numFont">
                    {{ row.item.accountNo }}
                </span>
            </template>

            <template v-slot:cell(quantity)="row">
                <span class="numFont">
                    {{ row.item.quantity.toLocaleString() }}
                </span>
            </template>

            <template v-slot:cell(duration)="row">
                <span class="numFont">
                    {{ row.item.duration.toLocaleString() + ' ' + getFrequency(row.item) }}
                </span>

            </template>

            <template v-slot:cell(rentRate)="row">
                <span class="numFont">
                    {{ formatMoneyValue(row.item.rentCurrency, row.item.rentRate) + ' ' +
                        row.item.rentFrequency.toUpperCase() }}
                </span>
            </template>

            <template v-slot:cell(vatExAmount)="row">
                <span class="numFont">
                    {{ formatMoneyValue(row.item.rentCurrency, row.item.vatExAmount) }}
                </span>
            </template>
        </b-table>

        <b-row>
            <b-col md="8" sm="12" class="my-1">
                <span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}
                    &nbsp;|&nbsp;
                    Total Rent Estimate: <span class="numFont">{{ formatMoneyValue('PHP', totalRentalAmount) }}</span>
                </span>
            </b-col>
            <b-col md="4" sm="12" class="my-1">
                <b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
                    class="my-0" />
            </b-col>
        </b-row>
    </div>
</template>

<script>
// Utils
import { BillingReportUtil } from '@/utils/billingReportUtil';

// Others
import _ from 'lodash';

export default {
    name: 'assets-on-hire',
    props: {
        assetsOnHire: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            fields: [
                {
                    key: 'accountNo',
                    label: 'Account No',
                    class: 'text-center'
                },
                {
                    key: 'assetOwner',
                    label: 'Asset Owner',
                },
                {
                    key: 'client',
                    label: 'Client',
                },
                {
                    key: 'assetType',
                    label: 'Asset Type',
                },
                {
                    key: 'quantity',
                    label: 'Quantity',
                    class: 'text-right'
                },
                {
                    key: 'duration',
                    label: 'Duration',
                    class: 'text-right'
                },
                {
                    key: 'rentRate',
                    label: 'Rent Rate',
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
                {
                    key: 'vatExAmount',
                    label: 'VAT Ex Amount',
                    class: 'text-right'
                },
            ],
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15, 25, 50, 100],
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,

            loggedUser: this.$store.getters.loggedUser,
            // Check for loader
            isLoading: false,
        }
    },
    computed: {
        totalRows() {
            return _.size(this.assetsOnHire);
        },
        totalRentalAmount() {
            return _.sumBy(this.assetsOnHire, o => { return o.vatExAmount; });
        }
    },
    methods: {
        getFrequency(item) {
            if (item.rentFrequency === 'Per Day') {
                return 'Days';
            }
            return 'Hours';
        },
        formatMoneyValue(currency, number) {
            return BillingReportUtil.formatMoneyValue(currency, number);
        },
    }
}
</script>

<style scoped>
</style>